import React, { useState } from 'react';
import PDF from 'react-pdf-js-infinite';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Typography, Button, useTheme } from '@mui/material';
import { Remove, Add } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setClientAgreementState, setStep } from 'src/reducers/prospectOptinAgreement';
import { getActiveStep } from 'src/selectors/prospectOptinAgreement';
import useFetch from 'src/react-hydra-query/useFetch';

const buttonStyle = {
  margin: '0 10px',
  fontSize: '36px',
};

interface ZoomButtonsProps {
  zoom: number;
  onZoom: (n: number) => void;
  disabled: boolean;
}

const ZoomButtons = ({ onZoom, zoom, disabled }: ZoomButtonsProps) => (
  <div style={{ position: 'absolute', right: '20px', bottom: '20px', zIndex: 2 }}>
    <Button
      disabled={disabled || zoom <= 0.5}
      style={buttonStyle}
      onClick={() => onZoom(zoom - 0.5)}
    >
      <Remove />
    </Button>
    <Button disabled={disabled || zoom >= 3} style={buttonStyle} onClick={() => onZoom(zoom + 0.5)}>
      <Add />
    </Button>
  </div>
);

interface AgreementContratStepProps {
  prospectOptinAgreementId: string;
}

const AgreementContratStep = ({ prospectOptinAgreementId }: AgreementContratStepProps) => {
  const { spacing } = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeStep = useSelector(getActiveStep);

  const onNextStep = (state) => {
    dispatch(setClientAgreementState(state));
    dispatch(setStep(activeStep + 1));
  };

  const { data: file } = useFetch({
    url: `${prospectOptinAgreementId}/document`,
    params: {},
    responseType: 'blob',
  });
  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Grid container direction="column" style={{ height: '100%', gridRowGap: spacing(1) }}>
      <Grid item>
        <Typography style={{ marginTop: 0 }} variant="h5" align="center">
          {intl.formatMessage({ id: 'prospect-optin-agreement.contract' })}
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        style={{
          flex: 1,
          borderBottom: '1px solid #f7f6f6',
          borderTop: '1px solid #f7f6f6',
          position: 'relative',
          backgroundColor: '#fff',
        }}
      >
        {file && (
          <>
            <ZoomButtons zoom={zoom} disabled={isLoading} onZoom={setZoom} />
            <div style={{ overflow: 'auto', height: '100%', width: '100%', position: 'absolute' }}>
              <PDF
                file={file}
                key={zoom}
                scale={zoom}
                onDocumentComplete={() => setIsLoading(false)}
              />
            </div>
          </>
        )}
      </Grid>

      <Grid
        container
        wrap="wrap"
        justifyContent="flex-end"
        alignItems="center"
        style={{ gridGap: spacing(3), marginTop: spacing(1), paddingRight: spacing(1) }}
      >
        <Grid item>
          <Button color="primary" onClick={() => onNextStep('refuse')} variant="outlined">
            <FormattedMessage id="prospect-optin-agreement.sign.refuse" />
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={() => onNextStep('accept')} variant="contained">
            <FormattedMessage id="prospect-optin-agreement.sign.accept" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AgreementContratStep;
