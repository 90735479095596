import { createSelector } from '@reduxjs/toolkit';

export const getProspectOptinAgreement = (state) => state.prospectOptinAgreement;

export const getActiveStep = createSelector(
  [getProspectOptinAgreement],
  (prospectOptinAgreement) => prospectOptinAgreement.activeStep
);

export const getClientAgreementState = createSelector(
  [getProspectOptinAgreement],
  (prospectOptinAgreement) => prospectOptinAgreement.clientAgreementState
);

export const getSteps = createSelector([getProspectOptinAgreement], (prospectOptinAgreement) => {
  return prospectOptinAgreement.steps;
});
