import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  activeStep: 0,
  clientAgreementState: 'pending',
  steps: ['prospect-optin-agreement.contract', 'prospect-optin-agreement.sign'],
};

const SET_STEP = 'prospectOptinAgreement/SET_STEP';
const RESET = 'prospectOptinAgreement/RESET';
const SET_CLIENT_AGREEMENT_STATE = 'prospectOptinAgreement/SET_CLIENT_AGREEMENT_STATE';

// ------------------------------------
// Actions
// ------------------------------------

export const setStep = (step) => ({
  type: SET_STEP,
  step,
});

export const reset = () => ({
  type: RESET,
});

export const setClientAgreementState = (state) => ({
  type: SET_CLIENT_AGREEMENT_STATE,
  state,
});

// ------------------------------------
// Handlers
// ------------------------------------

const handleSetStep = (prevState, { step }) => ({
  ...prevState,
  activeStep: step,
});

const handleReset = () => initialState;

const handleSetClientAgreementState = (prevState, { state }) => ({
  ...prevState,
  clientAgreementState: state,
});
// ------------------------------------
// Reducer
// ------------------------------------

export default createReducer(initialState, {
  [SET_STEP]: handleSetStep,
  [RESET]: handleReset,
  [SET_CLIENT_AGREEMENT_STATE]: handleSetClientAgreementState,
});
