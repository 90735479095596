import React, { ReactNode } from 'react';
import { Grid, Stepper, Step, StepButton } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveStep, getSteps } from 'src/selectors/prospectOptinAgreement';
import { setStep } from 'src/reducers/prospectOptinAgreement';
import PoweredBy from '../SaleContract/SaleContractLayout/PoweredBy';
import LogoLarge from '../../../components/LogoLarge';

interface ProspectOptinAgreementLayoutProps {
  children: ReactNode;
}

const ProspectOptinAgreementLayout = ({ children }: ProspectOptinAgreementLayoutProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeStep = useSelector(getActiveStep);
  const steps = useSelector(getSteps);
  return (
    <Grid
      container
      direction="column"
      style={{ height: '100vh', paddingTop: 20, paddingBottom: 10 }}
    >
      <Grid
        container
        justifyContent="center"
        style={{ backgroundColor: '#fff', borderBottom: '1px solid #f7f6f6' }}
      >
        <Stepper style={{ width: '90%' }} activeStep={activeStep}>
          {steps.map((step, index) => (
            <Step key={step}>
              <StepButton color="primary" onClick={() => dispatch(setStep(index))}>
                {intl.formatMessage({ id: step })}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container style={{ flex: 1 }}>
        <Grid item xs={3} style={{ background: '#fff' }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            style={{ height: '100%', position: 'relative' }}
            alignItems="center"
          >
            <Grid container alignItems="center" justifyContent="center" style={{ flex: 1 }}>
              <LogoLarge dimension={180} />
            </Grid>
            <PoweredBy positionStyle={{ marginBottom: '14px' }} />
          </Grid>
        </Grid>

        <Grid item container xs={9} style={{ height: '100%', width: '100%', padding: '20px' }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProspectOptinAgreementLayout;
