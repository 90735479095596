import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Sketchfield from '@stadline/mui-sketch-field';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveStep, getClientAgreementState } from 'src/selectors/prospectOptinAgreement';
import { setStep } from 'src/reducers/prospectOptinAgreement';
import { getDeviceId } from '../../../../selectors/config';
import useSocketConnection from '../../../../socket.io-react/useSocketConnection';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const AgreementSignStep = () => {
  const intl = useIntl();
  const { spacing } = useTheme();
  const dispatch = useDispatch();

  const deviceId = useSelector(getDeviceId);
  const activeStep = useSelector(getActiveStep);
  const agreementClientState = useSelector(getClientAgreementState);

  const handleMessage = (_, message) => {
    switch (message.type) {
      case 'prospect_agreement_signature_reset':
        setValue(null);
        setIsWaiting(false);
        break;
      default:
      // do nothing
    }
  };
  const broadcast = useSocketConnection(`/:clientToken/devices/${deviceId}`, handleMessage);

  const [isWaiting, setIsWaiting] = useState(false);
  const [value, setValue] = useState(null);

  const onSketchClear = () => {
    setValue(null);
  };
  const onChange = (val) => {
    setValue(val);
  };
  const onConfirm = async () => {
    setIsWaiting(true);
    broadcast('message', {
      type: 'prospect_agreement_signature_signed',
      payload: {
        signatureValue: value,
        agreementClientState,
      },
    });
    await delay(3000);
    setIsWaiting(false);
  };

  const onBack = () => {
    dispatch(setStep(activeStep - 1));
  };

  return (
    <Grid container direction="column" style={{ height: '100%', gridGap: spacing(1) }}>
      <Grid item>
        <Typography variant="h5" align="center" style={{ marginTop: 0 }}>
          <FormattedMessage
            id={`prospect-optin-agreement.sign.step.title.${agreementClientState}`}
          />
        </Typography>
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Sketchfield
          value={value}
          placeholder={intl.formatMessage({
            id: 'prospect-optin-agreement.sign.step.sketchfield.placeholder',
          })}
          width="100%"
          height="350px"
          onChange={onChange}
        />

        <Box py={1} textAlign="right">
          <Button color="primary" onClick={onSketchClear} disabled={isWaiting || !value}>
            <FormattedMessage id="prospect-optin-agreement.sign.step.delete-signature" />
          </Button>
        </Box>
      </Grid>

      <Grid
        container
        wrap="wrap"
        justifyContent="flex-end"
        alignItems="center"
        style={{ gridGap: spacing(3), marginTop: spacing(1), paddingRight: spacing(1) }}
      >
        <Grid item>
          <Button color="primary" onClick={onBack} variant="outlined">
            <FormattedMessage id="prospect-optin-agreement.sign.step.back" />
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            disabled={!value || isWaiting}
            onClick={onConfirm}
            variant="contained"
          >
            <FormattedMessage id="prospect-optin-agreement.sign.step.confirm" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AgreementSignStep;
