import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { reset } from 'src/reducers/prospectOptinAgreement';
import SaleContract from './SaleContract';
import MandateScreen from './MandateScreen';
import PaylineWalletScreen from './PaylineWalletScreen';
import StandbyScreen from './StandbyScreen';
import NoMatch from '../NoMatch';
import CancellationCertificate from './CancellationCertificate/CancellationCertificate';
import { change as changeScreen, reset as resetScreen } from '../../reducers/clientScreen';
import { getDeviceId } from '../../selectors/config';
import { getClientScreen } from '../../selectors/clientScreen';
import { getPaylineState } from '../../selectors/payline';
import useSocketConnection from '../../socket.io-react/useSocketConnection';
import ProspectOptinAgreement from './ProspectOptinAgreement';

const ClientScreen = () => {
  const deviceId = useSelector(getDeviceId);
  const screen = useSelector(getClientScreen);
  const paylineState = useSelector(getPaylineState);

  const dispatch = useDispatch();
  const handleMessage = (_, message) => {
    switch (message.type) {
      case 'sale_contract_signature_enter':
        dispatch(changeScreen('contract-signature', message));
        break;
      case 'mandate_signature_enter':
        dispatch(changeScreen('mandate-signature', message));
        break;
      case 'payline_wallet_enter':
        dispatch(changeScreen('payline_wallet', message));
        break;
      case 'cancellation_certificate_signature_enter':
        dispatch(changeScreen('certificate-signature', message));
        break;
      case 'prospect_agreement_signature_enter':
        dispatch(changeScreen('prospect_agreement_signature', message));
        break;
      case 'prospect_agreement_signature_exit':
        dispatch(reset());
        dispatch(resetScreen());
        break;
      case 'mandate_signature_exit':
      case 'sale_contract_signature_exit':
      case 'cancellation_certificate_signature_exit':
      case 'sale_stop':
        dispatch(resetScreen());
        break;
      case 'payline_wallet_exit':
        paylineState !== 'PAYMENT_SUCCESS' && dispatch(resetScreen());
        break;
      default:
      // do nothing
    }
  };

  useSocketConnection(`/:clientToken/devices/${deviceId}`, handleMessage);

  // from resamania-webapps's useWillUnmount
  useLayoutEffect(
    () => () => {
      dispatch(resetScreen());
    },
    [dispatch]
  );

  switch (screen.name) {
    case 'standby':
      return <StandbyScreen />;
    case 'contract-signature':
      return <SaleContract key={screen.id} {...screen.props} />;
    case 'mandate-signature':
      return <MandateScreen key={screen.id} {...screen.props} />;
    case 'payline_wallet':
      return <PaylineWalletScreen key={screen.id} {...screen.props} />;
    case 'certificate-signature':
      return <CancellationCertificate key={screen.id} {...screen.props} />;
    case 'prospect_agreement_signature':
      return <ProspectOptinAgreement key={screen.id} {...screen.props} />;
    default:
      return <NoMatch />;
  }
};

ClientScreen.propTypes = {
  screen: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    props: PropTypes.object,
  }),
};

export default ClientScreen;
