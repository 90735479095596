import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveStep, getSteps } from 'src/selectors/prospectOptinAgreement';
import ProspectOptinAgreementLayout from './ProspectOptinAgreementLayout';
import AgreementContratStep from './AgreementContratStep/AgreementContratStep';
import AgreementSignStep from './AgreementSignStep/AgreementSignStep';

interface ProspectOptinAgreementProps {
  prospectOptinAgreementId: string;
}

const ProspectOptinAgreement = ({ prospectOptinAgreementId }: ProspectOptinAgreementProps) => {
  const activeStep = useSelector(getActiveStep);
  const steps = useSelector(getSteps);

  return (
    <ProspectOptinAgreementLayout>
      {(() => {
        switch (steps[activeStep]) {
          case 'prospect-optin-agreement.contract':
            return <AgreementContratStep prospectOptinAgreementId={prospectOptinAgreementId} />;
          case 'prospect-optin-agreement.sign':
            return <AgreementSignStep />;

          default:
            return null;
        }
      })()}
    </ProspectOptinAgreementLayout>
  );
};

export default ProspectOptinAgreement;
